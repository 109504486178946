@use 'sass:map';

@mixin elevate() {
    position: relative;
    z-index: 2;
}

// top gradient bar
// -----------------------------------------------------------------------------

@mixin top-bar($width: 66.6%) {
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        background: map.get($gradients, 'btn-primary');
        width: $width;
        height: .5em;
        z-index: 2;
        transition: width .125s linear;
        transform: translateX(-50%);
    }
}


// skewed backer
// -----------------------------------------------------------------------------

@mixin skew-backer($background, $height: 100%, $anchorBottom: false) {
    position: relative;

    .container {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: $height;
        left: 0;
        transform: skewY(-10.7deg);
        transform-origin: 100% 0;
        background: $background;
        z-index: 1;

        @if($anchorBottom) {
            bottom: 0;
        } @else {
            top: 0;
        }

        @content;
    }
}