@use 'sass:map';

// sidebar
// -----------------------------------------------------------------------------

body .row.row-product-selection {
    .sidebar {
        z-index: 5;

        .list-group-item:first-child {
            border-radius: 0;
        }
    }
}


// features
// -----------------------------------------------------------------------------

body #order-universal_slider .product-container .feature-container {
    .container-with-progress-bar {
        span {
            font-size: 1.2rem;
            line-height: 1.1;
        }

        .progress.small-progress .progress-bar {
            background: $primary;
        }
    }
}

// pricing
// -----------------------------------------------------------------------------

body #order-universal_slider {

    .product-container .price-container {
        font-weight: 900;
        font-size: 1em;
        background: $light;
        padding: 1em;
        margin-top: 0;

        .order-button {
            @extend .btn;
            background: $green;
            border: transparent;
            margin-top: 1em;

            &:hover {
                @extend .btn;
                background: $primary;
                margin-top: 1em;
            }
        }
    }
}

body #order-universal_slider {

    .striped-container {
        background: #d9effb;
        padding-top: 3em !important;
        padding-bottom: 3em !important;
        margin-bottom: 3em !important;
    }

    .product-selector {
        .irs-single {
            background: #d9effb;
        }

        .irs-line-left {
            background: $primary;
        }
        
        .irs-line-mid {
            background: $primary;
        }

        .irs-line-right {
            background: $primary;
        }

        .color-last {
            background: $primary;
        }
    
    }

    .product-container {
        // background: $light;

        .price-container {
            // padding-right: 2em;
        }

        .price-cont {
            color: $primary !important;
            font-weight: 900;
            display: flex;

            span {
                color: inherit !important;
                display: inline;
            }
        }

        
    }

    


    // .product-selector .irs-single {
        // background: $white;
    // }

    // .product-selector .irs-bar {
        // background: #cf0;
    // }



}