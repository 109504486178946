body {
    .bg-color-blue {
        background: $blue;
    }

    .bg-color-green {
        background: $green;
    }

    .bg-color-red {
        background: $red;
    }

    .bg-color-asbestos {
        background: $magenta;
    }
}



// .bg-color-blue {
//     background: map.get($masthead-gradients, 'cyan');
// }

// .bg-color-green {
//     background: map.get($masthead-gradients, 'green');
// }

// .bg-color-red {
//     background: map.get($masthead-gradients, 'magenta');
// }

// .bg-color-gold {
//     background: map.get($masthead-gradients, 'yellow');
// }