// Color system
// -----------------------------------------------------------------------------

$code-color: #666;

$white:    #fff;
$gray-100: #F3F3F3;
$gray-200: #EFEFEF;
$gray-300: #EEEEEE;
$gray-400: #E5E5E5;
$gray-500: #C4C4C4;
$gray-600: #6c757d !default;
$gray-700: #5D5F62;
$gray-800: #343a40 !default;
$gray-900: #231F20;
$black:    #000;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;

// color-variables
$blue:     #4692FB;
$pink:    #CA63E3;
$green:   #6DC096;
$yellow: #FFC645;
$cyan: #75C5F0;
$magenta: #74257C;

$green-bright: #66cc00;

$primary:       $blue;

$color-contrast-dark:      $black;
$color-contrast-light:     $white;

$blue-100: rgba(118, 197, 240, 0.28);
$blue-200: #f0f6ff;
$blue-400: #4893FF;
$blue-500: $blue;
$blue-600: #3971dc;
$blue-700: #3668d5;
$blue-800: #2036A7;
$blue-900: #102143;

$pink-100:   rgba(214, 90, 158, 0.15);
$orange-100: rgba(254, 185, 65, 0.21);
$green-100:  rgba(24, 177, 148, 0.17);
$teal-100:   rgba(147, 211, 207, 0.37);
$cyan-100:   $blue-100;


// Body
// -----------------------------------------------------------------------------

$body-bg:    $white;
$body-color: $gray-700;


// Links
// -----------------------------------------------------------------------------

$link-decoration: none;


// Paragraphs
// -----------------------------------------------------------------------------

$paragraph-margin-bottom: 1rem;


// Grid breakpoints
// -----------------------------------------------------------------------------

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1120px,
//   xxl: 1320px
// );


// Grid containers
// -----------------------------------------------------------------------------

// $container-max-widths: (
//   sm: 540px,
//   md: 868px,
//   lg: 960px,
//   xl: 1086px,
//   xxl: 1260px
// );


// Grid columns
// -----------------------------------------------------------------------------

$grid-columns:      12;
$grid-gutter-width: 1.5rem;
$grid-row-columns:  6;


// Container padding
// -----------------------------------------------------------------------------

$container-padding-x: $grid-gutter-width * .5 !default;


// Components
// -----------------------------------------------------------------------------

$border-color: $gray-300;

$border-radius:      0;
$border-radius-sm:   .2rem;
$border-radius-lg:   .3rem;
$border-radius-pill: 50rem;

$font-family-sans-serif: 'sofia-pro', sans-serif;
$font-family-monospace:  SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-root: 18px;
$font-size-base: 1rem;
$font-size-sm:   $font-size-base * .83;
$font-size-lg:   $font-size-base * 1.25;

$h1-font-size: $font-size-base * 4;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2.75;
$h4-font-size: $font-size-base * 2;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom:.5em;
$headings-font-weight:  900;
$headings-line-height:  1.1;
$headings-color:        $blue-900;

$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1.15rem;
$input-btn-font-size: $font-size-sm;

$input-btn-border-width: 0;


// Buttons
// -----------------------------------------------------------------------------

$btn-line-height:   $input-btn-font-size;
$btn-white-space:   nowrap; 
$btn-border-radius: 1rem;


// Spacing
// -----------------------------------------------------------------------------

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .5,
  2: $spacer * 1,
  3: $spacer * 2,
  4: $spacer * 3,
  5: $spacer * 4,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
);


// Forms
// -----------------------------------------------------------------------------

$input-padding-y:   1.4em;
$input-padding-x:   1.2em;
$input-font-size:   1em;
$input-line-height: 1.2;

$input-bg:           $white;
$input-border-color: #BABCC0;
$input-border-width: 1px;

$input-border-radius: 0;

$input-focus-border-color: $primary;
$input-focus-box-shadow:   none;


// Navs
// -----------------------------------------------------------------------------

$nav-link-padding-y:   .2rem;
$nav-link-padding-x:   0;
$nav-link-font-size:   $font-size-sm;
$nav-link-color:       $white;
$nav-link-hover-color: $white;
$nav-link-transition:  border-color .15s ease-in-out;


// Navbar
// -----------------------------------------------------------------------------

$navbar-padding-y: $spacer * 1.5;

$navbar-nav-link-padding-x: 0;

$navbar-toggler-border-radius: 0;

$navbar-dark-color:           $white;
$navbar-dark-hover-color:     $white;
$navbar-dark-active-color:    $white;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$primary}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;


// Cards
// -----------------------------------------------------------------------------

$card-spacer-y:     2em;
$card-spacer-x:     3.625em;
$card-border-width: 0;
$card-height:       100%;


// Options
// -----------------------------------------------------------------------------

$enable-negative-margins: true;