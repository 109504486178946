@use 'sass:map';
@use 'sass:color';

.btn {
    border-radius: 1rem;
    line-height: 1em;
    font-size: .9rem;
    padding: .5rem 1.15rem .6em;

    &.btn-primary {
        background: map.get($gradients, 'btn-primary');
        border: transparent;

    }

    &.btn-danger {
        background: $red;
    }

    &.btn-success, 
    &.btn-green {
        border-color: $green;
        color: $white;
        background: color.adjust(#83db94, $lightness: -25%);
        // background: $green;
    }

    &.btn-lg {
        font-size: 1.2rem;
        border-radius: 1.2rem;
        padding: .5rem 1.33rem .6rem;
        font-weight: 500;
    }

    i {
        margin: 0 .125rem;
        font-size: .8em;
        // &:first-child {
        //     margin: 0 .25em 0 0;
        // }

        // &:last-child {
        //     margin: 0 0 0 .25em;
        // }
    }

    &-xs {
        padding: .25rem .625rem .4rem .625rem;
    }
}