body #order-standard_cart .cart-body {
    .view-cart-items-header {
        background: $gray-700;
    }

    .order-summary h2 {
        background: $gray-700;
    }

    .btn-checkout {
        font-size: 1.2rem;
        border-radius: 1.5rem;
        padding: .75rem 1.5rem .9rem;
        font-weight: 500;
    }

    #btnCompleteOrder {
        margin-top: 1.5em;
    }
}
