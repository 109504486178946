@use 'sass:map';

.site-footer {
    position: relative;
    padding-top: 15em !important;
    padding-bottom: 5em !important;
    margin-top: 5em;

    .container {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 200%;
        left: 0;
        top: -10%;
        transform: skewY(-10.7deg);
        transform-origin: 100% 0;
        background: $light;
        z-index: 1;
    }


}

// Logo
// -----------------------------------------------------------------------------

.site-footer svg {
    max-width: 158px;

    path[fill="white"] {
        fill: #0e2244;
    }
}

// standard nav
// -----------------------------------------------------------------------------

.site-footer-nav {
    li {
        margin-bottom: .6em;

        &:first-child {
            font-weight: 900;
            margin-bottom: 1em;

            a, span {
                color: $gray-800;
            }
        }
    }
}


// social links
// -----------------------------------------------------------------------------

.social-links {
    .col {
        flex-grow: 0;

        &:nth-child(2) {
            svg {
                width: 46%;
            }
        }
    }

    svg {
        width: 56%;
    }

    a {
        display: block;
        width: 2.2em;
        height: 2.2em;
        background: $blue;
        color: $white;
        border-radius: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $blue;
        transition: all .125s linear;
    }

    a:hover {
        background: $white;
        color: $blue;
    }
}


// service buttons
// -----------------------------------------------------------------------------

// [shared] service-specific
// -----------------------------------------------------------------------------

.service-button-shared { background: linear-gradient(261.94deg, #F5802E 9.24%, #FFC645 105.41%); }

// [reseller] service-specific
// -----------------------------------------------------------------------------

.service-button-reseller { background: linear-gradient(261.94deg, #017D6A 9.24%, #5BC8C9 105.41%); }

// [semi-dedicated] service-specific
// -----------------------------------------------------------------------------

.service-button-semi-dedicated { background: linear-gradient(261.94deg, #3176B7 9.24%, #62B5E5 105.41%); }


// [dedicated] service-specific
// -----------------------------------------------------------------------------

.service-button-dedicated { background: linear-gradient(261.94deg, #75C5F0 9.24%, #97D5C9 105.41%); }


// [vps] service-specific
// -----------------------------------------------------------------------------

.service-button-vps { background: linear-gradient(261.94deg, #74257C 9.24%, #E964A4 105.41%) }


// [managed-wp] service-specific
// -----------------------------------------------------------------------------

.service-button-managed-wp { background: linear-gradient(261.94deg, #01AC93 9.24%, #B2D99F 105.41%); }


// [domains] service-specific
// -----------------------------------------------------------------------------

.service-button-domains { background: linear-gradient(265.84deg, $blue 6.8%, #2036A7 115.96%); }


// service button
// -----------------------------------------------------------------------------

.service-button {
    display: block;
    padding: .2em;
    margin-top: map.get($spacers, 3);
    position: relative;
    align-items: center;
    border-radius: 2em;
    overflow: hidden;
    text-decoration: none !important;

    &:first-child {
        margin-top: 0;
    }

    &:before {
        content: '';
        position: absolute;
        background: $white;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .15s linear;
    }

    &> div {
        background: $white;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: .6em 1em;
        border-radius: 2em;

        div {
            width: auto;
            height: auto;
            max-width: 2em;
            max-height: 2em;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    span {
        color: $gray-800;
        font-weight: 900;
        margin-left: 1em;
        line-height: 1.2em;
    }

    &:hover {
        &:before {
            opacity: 0;
        }
    }
}





// Copyright
// -----------------------------------------------------------------------------

.site-copyright {
    background: $blue-900;
    color: $blue;
}


//     overflow: hidden;

//     body.footer-extended & {
//         padding-top: 8rem !important;

//         @include media-breakpoint-down(md) {
//             padding-top: 4rem !important;
//         }
//     }
    
//     svg {
//         max-width: 158px;

//         path[fill="white"] {
//             fill: #0e2244;
//         }
//     }
// }







