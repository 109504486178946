// top bar
// -----------------------------------------------------------------------------

#header .topbar {
    font-size: .875em;
    background: linear-gradient(265.84deg,#4692fb 6.8%,#2036a7 115.96%);
    background: $blue-900;
    
    #accountNotifications {
        color: $light;
        outline: none;
    }

    .active-client-new {
        color: $white;

        .label-col,
        .btn.btn-active-client {
            color: $white;
            white-space: nowrap;
            padding: .35rem .75rem .5rem;
            font-size: .8rem;
            line-height: 1;
            border-radius: 1em;
            box-sizing: border-box;
            border: 1px solid rgba($white, .15);
        }

        .label-col {
            background: rgba($white, .15);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .btn.btn-active-client {
            border: 1px solid rgba($white, .3);
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-right: 1rem;
            flex-basis: auto;
        }

        .links-col {
            padding-left: 0;
            padding-right: 0;
            .btn {
                color: $white;
            }
        }
    }


    .active-client {
        .input-group-text,
        .btn.btn-active-client {
            color: $white;
            border: 1px solid rgba($white, .15);
            border-radius: 1em;
            font-size: .8rem;
            line-height: 1;
            padding: .35rem .75rem .5rem;
        }

        .input-group-text {
            background: rgba($white, .15);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            vertical-align: middle;
        }

        .btn.btn-active-client {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .btn-group {
            align-items: center;
        }
    }

    // .input-group.active-client {
    //     .input-group-text {
    //         font-size: 0.8rem;
    //         color: $white;
    //         background: rgba($white, .25);
    //         border-radius: 1rem;
    //         border-top-right-radius: 0;
    //         border-bottom-right-radius: 0;
    //         padding: .375rem .75rem;
    //         line-height: 1.5;
    //     }

    //     .btn.btn-active-client {
    //         border: 1px solid #ced4da;
    //         color: $white;
    //         border-radius: 1rem;
    //         font-size: 0.8rem;
    //         border-left: none;
    //         border-top-left-radius: 0;
    //         border-bottom-left-radius: 0;
    //         padding: .375rem .75rem;
    //         line-height: 1.5;
    //     }
    // }
}


// wrapper
// -----------------------------------------------------------------------------

.site-header {
    background: linear-gradient(265.84deg,#4692fb 6.8%,#2036a7 115.96%);

    @include media-breakpoint-down(lg) {
        position: fixed;
        // background: $white;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }
}


// brand
// -----------------------------------------------------------------------------

.site-header .navbar-brand {
    display: block;
    width: 100%;
    max-width: 6.4rem;
    height: auto;
    font-size: .8em;

    svg {
        @include media-breakpoint-down(lg) {
            path {
                fill: $blue-900;

                &.x {
                    fill: $blue-400;
                }
            }
        }
    }
}


// toggler
// -----------------------------------------------------------------------------

.navbar-toggler {
    color: $primary;
    border-color: none;

    &-icon {
        color: $primary;
    }
}


// primary navigation
// -----------------------------------------------------------------------------

.site-header.navbar-dark .navbar-nav {
    .nav-link, .dropdown-toggle {
        color: $white;
        opacity: 1;
    }

    .nav-item {
        color: $white;
        opacity: 1;
    }


    .dropdown-item {
        color: $primary;
        background: $white;
        transition: background .125s linear, color .125s linear;

        .nav-link {
            color: inherit;
        }

        &:hover {
            background: $primary;
            color: $white;
        }
    }

    &.toolbar {
        margin-left: 1em;
        .badge.badge-info {
            background: rgba($white, .75);
            color: $primary;
        }
    }
}










.navbar.navbar-dark {
    @include media-breakpoint-down(md) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    // .nav-link {
    //     @include media-breakpoint-down(lg) {
    //         color: $primary;
    //         font-size: 1.5em;
    //     }

    //     @include media-breakpoint-down(md) {
    //         font-size: 1.25em;
    //     }

    //     &.active, 
    //     &:hover {
    //         @include media-breakpoint-down(lg) {
    //             color: $primary;
    //         }
    //     }
    // }

    .btn {
        @include media-breakpoint-down(lg) {
            background: $primary;
            color: $white;
            margin-top: 1em;
        }
    }
}





.site-nav {

    @include media-breakpoint-down(lg) {
        padding-top: 1em;
    }

    .nav-item {
        margin: 0 1em;

        @include media-breakpoint-down(xl) {
            margin: 0 .6em;
        }

        @include media-breakpoint-down(lg) {
            margin: 0;
            border-top: 1px solid $gray-200;
            padding: .5em 0;
        }
    }

    // .nav-link {
    //     border-top: 2px solid transparent;
    //     border-bottom: 2px solid rgba($white, 0);

    //     &:hover {
    //         border-bottom: 2px solid rgba($white, .666);
    //     }

    //     &.active {
    //         border-bottom: 2px solid rgba($white, 1);
    //     }
    // }
}