body .alert {
    &-info {
        background: $gray-300;
        border-color: darken($gray-300, 5%);
    }

    &-success {
        background: rgba($green, .5);
        border-color: rgba(darken($green, 5%), .5);
    }
}