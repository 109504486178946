@use 'sass:map';

// base card
// -----------------------------------------------------------------------------

body .card {

    &.card-accent-blue {
        border-top-color: $blue;
    }

    &.card-accent-green {
        border-top-color: $green;
    }

    &.card-accent-asbestos {
        border-top-color: $magenta;
    }

    &.card-accent-gold {
        border-top-color: $yellow;;
    }
}


// primary content card
// -----------------------------------------------------------------------------

body .primary-content .card {
    margin-bottom: map.get($spacers, 3);
}

// sidebar card
// -----------------------------------------------------------------------------

body .card.card-sidebar {
    .card-header {
        background: $primary;
        background: linear-gradient(90deg, #3971DC 0%, #4692FB 91.98%);
        color: $white;
    }

    .card-title {
        font-weight: 900;
        letter-spacing: 0.04em;
    }
}

// body .card {

//     &-header {
//         background: $primary;
//         color: $white;

//     }
// }


// $masthead-gradients: (
//   'primary': linear-gradient(265.84deg, $blue 6.8%, #2036A7 115.96%),
//   'orange':  linear-gradient(261.94deg, #F5802E 9.24%, #FFC645 105.41%),
//   'blue':    linear-gradient(261.94deg, #3176B7 9.24%, #62B5E5 105.41%),
//   'cyan':    linear-gradient(261.94deg, #75C5F0 9.24%, #97D5C9 105.41%),
//   'pink':    linear-gradient(261.94deg, #74257C 9.24%, #E964A4 105.41%),
//   'green':   linear-gradient(261.94deg, #01AC93 9.24%, #B2D99F 105.41%),
//   'turquoise': linear-gradient(261.94deg, #017D6A 9.24%, #5BC8C9 105.41%),
// );