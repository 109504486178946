.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: hidden;

    #main-body {
        flex-grow: 1;
        padding: 4em 0 0;

        @include skew-backer($white);
    }
}

// elevate section on z-axis
// -----------------------------------------------------------------------------

.elevate {
    position: relative;
    z-index: 2;
}

